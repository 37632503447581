import axios from "axios";
import store from "../store/store";
import { getUnixTime } from "date-fns";
import EventBus from "../event-bus";

// Função de fábrica para criar instâncias de API
function createApi(baseURL, options = {}) {
    const api = axios.create({
        baseURL,
    });

    // Interceptors padrão
    api.interceptors.request.use(
        (config) => addAuthenticationHeaders(config, options),
        Promise.reject
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => handleResponseError(error, options)
    );

    return api;
}

// Função para adicionar headers de autenticação e outros parâmetros
function addAuthenticationHeaders(config, options = {}) {
    if (options.requiresAuth) {
        // Verifica expiração do token
        if (
            store.getters.getExpire &&
            getUnixTime(new Date()) > store.getters.getExpire
        ) {
            store.dispatch("setLoggout", {
                user: {},
                token: "",
                isLogged: false,
                expire: null,
            });
        }

        // Atualiza tempo da última ação do usuário
        store.dispatch("refreshUserActivityTime");

        // Adiciona token de autorização se necessário
        if (store.getters.getAccessToken) {
            config.headers["Authorization"] = store.getters.getAccessToken;
        }
    }

    return config;
}

// Função para tratamento de erros
function handleResponseError(error, options = {}) {
    if (options.requiresAuth) {
        // Tratamento de erros específicos da API principal
        if (error.response?.data?.code) {
            if (error.response.data.code === "PASSWORD_CHANGE_REQUIRED") {
                EventBus.emit("show-global-dialog", "PASSWORD_CHANGE_REQUIRED");
            }
            if (error.response.data.code === "RELOG_REQUIRED") {
                EventBus.emit("show-global-dialog", "RELOG_REQUIRED");
            }
        }
    } else {
        // Tratamento de erros para outras APIs
        console.error("API Error:", error);
    }

    return Promise.reject(error);
}

// Criação das instâncias de API
export const api = createApi(process.env.VUE_APP_BASE_URL, {
    requiresAuth: true,
});
export const sgApi = createApi(process.env.VUE_APP_SG_URL);

// Mantendo a compatibilidade com o código existente
export default api;
